var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Data Kompetensi Dasar Keterampilan ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kelas","label-for":"level_id"}},[_c('validation-provider',{attrs:{"name":"level_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"level_id","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Kelas"},model:{value:(_vm.dataParent.level_id),callback:function ($$v) {_vm.$set(_vm.dataParent, "level_id", $$v)},expression:"dataParent.level_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Mata Pelajaran","label-for":"academic_subject_id"}},[_c('validation-provider',{attrs:{"name":"academic_subject_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"academic_subject_id","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Mata Pelajaran"},model:{value:(_vm.dataParent.academic_subject_id),callback:function ($$v) {_vm.$set(_vm.dataParent, "academic_subject_id", $$v)},expression:"dataParent.academic_subject_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"type","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Type"},model:{value:(_vm.dataParent.type),callback:function ($$v) {_vm.$set(_vm.dataParent, "type", $$v)},expression:"dataParent.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Aktif","label-for":"active"}},[_c('validation-provider',{attrs:{"name":"active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"active","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Aktif"},model:{value:(_vm.dataParent.active),callback:function ($$v) {_vm.$set(_vm.dataParent, "active", $$v)},expression:"dataParent.active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Agama","label-for":"is_religion"}},[_c('validation-provider',{attrs:{"name":"is_religion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"is_religion","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Agama"},model:{value:(_vm.dataParent.is_religion),callback:function ($$v) {_vm.$set(_vm.dataParent, "is_religion", $$v)},expression:"dataParent.is_religion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.1","label-for":"kd1"}},[_c('validation-provider',{attrs:{"name":"kd1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd1","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.1"},model:{value:(_vm.dataParent.kd1),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd1", $$v)},expression:"dataParent.kd1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.2","label-for":"kd2"}},[_c('validation-provider',{attrs:{"name":"kd2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd2","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.2"},model:{value:(_vm.dataParent.kd2),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd2", $$v)},expression:"dataParent.kd2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.3","label-for":"kd3"}},[_c('validation-provider',{attrs:{"name":"kd3","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd3","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.3"},model:{value:(_vm.dataParent.kd3),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd3", $$v)},expression:"dataParent.kd3"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.4","label-for":"kd4"}},[_c('validation-provider',{attrs:{"name":"kd4","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd4","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.4"},model:{value:(_vm.dataParent.kd4),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd4", $$v)},expression:"dataParent.kd4"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.5","label-for":"kd5"}},[_c('validation-provider',{attrs:{"name":"kd5","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd5","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.5"},model:{value:(_vm.dataParent.kd5),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd5", $$v)},expression:"dataParent.kd5"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.6","label-for":"kd6"}},[_c('validation-provider',{attrs:{"name":"kd6","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd6","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.6"},model:{value:(_vm.dataParent.kd6),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd6", $$v)},expression:"dataParent.kd6"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.7","label-for":"kd7"}},[_c('validation-provider',{attrs:{"name":"kd7","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd7","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.7"},model:{value:(_vm.dataParent.kd7),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd7", $$v)},expression:"dataParent.kd7"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.8","label-for":"kd8"}},[_c('validation-provider',{attrs:{"name":"kd8","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd8","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.8"},model:{value:(_vm.dataParent.kd8),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd8", $$v)},expression:"dataParent.kd8"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Keterangan 4.9","label-for":"kd9"}},[_c('validation-provider',{attrs:{"name":"kd9","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd9","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.9"},model:{value:(_vm.dataParent.kd9),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd9", $$v)},expression:"dataParent.kd9"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.10","label-for":"kd10"}},[_c('validation-provider',{attrs:{"name":"kd10","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd8","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.10"},model:{value:(_vm.dataParent.kd10),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd10", $$v)},expression:"dataParent.kd10"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.11","label-for":"kd11"}},[_c('validation-provider',{attrs:{"name":"kd11","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd11","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.11"},model:{value:(_vm.dataParent.kd11),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd11", $$v)},expression:"dataParent.kd11"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.12","label-for":"kd12"}},[_c('validation-provider',{attrs:{"name":"kd12","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd12","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.12"},model:{value:(_vm.dataParent.kd12),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd12", $$v)},expression:"dataParent.kd12"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.13","label-for":"kd13"}},[_c('validation-provider',{attrs:{"name":"kd13","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd13","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.13"},model:{value:(_vm.dataParent.kd13),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd13", $$v)},expression:"dataParent.kd13"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.14","label-for":"kd14"}},[_c('validation-provider',{attrs:{"name":"kd14","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd14","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.14"},model:{value:(_vm.dataParent.kd14),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd14", $$v)},expression:"dataParent.kd14"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.15","label-for":"kd15"}},[_c('validation-provider',{attrs:{"name":"kd15","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd15","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.15"},model:{value:(_vm.dataParent.kd15),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd15", $$v)},expression:"dataParent.kd15"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.16","label-for":"kd16"}},[_c('validation-provider',{attrs:{"name":"kd16","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd16","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.16"},model:{value:(_vm.dataParent.kd16),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd16", $$v)},expression:"dataParent.kd16"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.17","label-for":"kd17"}},[_c('validation-provider',{attrs:{"name":"kd17","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd17","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.17"},model:{value:(_vm.dataParent.kd17),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd17", $$v)},expression:"dataParent.kd17"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.18","label-for":"kd18"}},[_c('validation-provider',{attrs:{"name":"kd18","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd18","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.18"},model:{value:(_vm.dataParent.kd18),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd18", $$v)},expression:"dataParent.kd18"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.19","label-for":"kd19"}},[_c('validation-provider',{attrs:{"name":"kd19","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd19","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.19"},model:{value:(_vm.dataParent.kd19),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd19", $$v)},expression:"dataParent.kd19"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Keterangan 4.20","label-for":"kd20"}},[_c('validation-provider',{attrs:{"name":"kd20","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kd20","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Keterangan 4.20"},model:{value:(_vm.dataParent.kd20),callback:function ($$v) {_vm.$set(_vm.dataParent, "kd20", $$v)},expression:"dataParent.kd20"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',{attrs:{"offset-md":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.formSubmitted}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }